import connect, { setBusinessCountry, setLegalName, setTradeName, setRegNumber, setRegDate, setType, saveBusiness, BusinessDate } from '../../../api';
import { useState } from 'react';
import Section from '../../ui/Section';
import Input from '../../ui/Input';
import CountrySelectModal from '../../ui/CountrySelectModal';
import BusinessNameSelectModal, { BusinessNameOption } from '../../ui/BusinessNameSelectModal';
import BusinessTypeSelectModal from '../../ui/BusinessTypeSelectModal';
import DatePicker from '../../ui/DatePicker';
import businessImage from "../../../assets/images/registration/business.webp";
import "./index.scss";

type BusinessProps = {
	country: string,
	legalName: string,
	tradeName: string,
	regNumber: string,
	regDate: BusinessDate,
	type: number,
	setCountry: (country: string) => void,
	setLegalName: (legalName: string) => void,
	setTradeName: (tradeName: string) => void,
	setRegNumber: (regNumber: string) => void,
	setRegDate: (regDate: BusinessDate) => void,
	setType: (type: number) => void,
	saveBusiness: () => void,
};

const Business = ({ country, legalName, tradeName, regNumber, regDate, type, setCountry, setLegalName, setTradeName, setRegNumber, setRegDate, setType, saveBusiness }: BusinessProps) => {
	const [showBlock, setShowBlock] = useState(false);
	const onSelectCountry = (value: string) => {
		setCountry(value);
	};
	const onSelectBusinessName = (value: string, option: BusinessNameOption) => {
		setLegalName(option.name);
		setRegNumber(option.regNumber);
		setShowBlock(true);
	};
	const onSelectBusinessType = (value: number) => {
		setType(value);
	};
	return (
		<Section title="Tell us about your business" label="Continue" image={businessImage} onSubmit={saveBusiness} description={<p>We need your business details to open your account</p>}>
			<div className="register-form-container">
				<CountrySelectModal ns="business" label="Country of residence" value={country} onSelect={onSelectCountry} />
				{!country || <BusinessNameSelectModal ns="business-name" label="Search business legal name" onSelect={onSelectBusinessName} />}
				{!showBlock || (
					<div className="create-business-block">
						<Input ns="business" label="Business legal name" value={legalName} onValueChange={setLegalName} />
						<Input ns="business" label="Business trade name" value={tradeName} onValueChange={setTradeName} />
						<p className='create-business-optional'>Optional</p>
						<Input ns="business" label="Companies house registration number" value={regNumber} onValueChange={setRegNumber} />
						<p className='create-business-optional'>Usually it looks like: 12345678, G1234567, LP123456</p>
						<DatePicker ns="incorporation" value={regDate} onChange={setRegDate} placeholder="Date of incorporation" />
						<BusinessTypeSelectModal ns="business-type" label="Business type" value={type} onSelect={onSelectBusinessType} />
					</div>
				)}
			</div>
		</Section>
	);
};

const mapState = ({ business: { country, legalName, tradeName, regNumber, regDate, type } }:
	{ business: { country: string, legalName: string, tradeName: string, regNumber: string, regDate: BusinessDate, type: number } }) =>
	({ country, legalName, tradeName, regNumber, regDate, type });

const mapDispatch = (dispatch: any) => ({
	setCountry: (country: string) => dispatch(setBusinessCountry(country)),
	setLegalName: (legalName: string) => dispatch(setLegalName(legalName)),
	setTradeName: (tradeName: string) => dispatch(setTradeName(tradeName)),
	setRegNumber: (regNumber: string) => dispatch(setRegNumber(regNumber)),
	setRegDate: (regDate: BusinessDate) => dispatch(setRegDate(regDate)),
	setType: (businessType: number) => dispatch(setType(businessType)),
	saveBusiness: () => dispatch(saveBusiness()),
});

export default connect(mapState, mapDispatch)(Business);