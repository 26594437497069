import { apiRequest } from '../api';
import { rejectRequest} from './index';
import type { ThunkAction } from "./index";

export type UserPhoneCodeDictionary = Array<{
	code: string
	phoneCode: string
}>;

export type UserAction =
	| { type: "user.getPhoneCodes", phoneCodes: UserPhoneCodeDictionary }
	| { type: "user.setCountry", country: string }
	| { type: "user.register", uuid: string }
	| { type: "user.setEmail", email: string }
	| { type: "user.saveEmail" }
	| { type: "user.setEmailCode", emailCode: string }
	| { type: "user.confirmEmail" }
	| { type: "user.setPhone", phone: string }
	| { type: "user.setPhonePrefix", phonePrefix: string }
	| { type: "user.savePhone" }
	| { type: "user.setPhoneCode", phoneCode: string }
	| { type: "user.confirmPhone" }
	| { type: "user.confirmPhone", phoneCode: string }
	| { type: "user.setPassword", password: string }
	| { type: "user.setPasswordValid", valid: boolean }
	| { type: "user.confirmPassword", password: string }
	| { type: "user.confirmPasswordValid", valid: boolean }
	| { type: "user.savePassword" }

export const setCountry = (country: string): UserAction => ({ type: "user.setCountry", country });
export const setEmail = (email: string): UserAction => ({ type: "user.setEmail", email });
export const setEmailCode = (emailCode: string): UserAction => ({ type: "user.setEmailCode", emailCode });
export const setPhone = (phone: string): UserAction => ({ type: "user.setPhone", phone });
export const setPhonePrefix = (phonePrefix: string): UserAction => ({ type: "user.setPhonePrefix", phonePrefix });
export const setPhoneCode = (phoneCode: string): UserAction => ({ type: "user.setPhoneCode", phoneCode });
export const setPassword = (password: string): UserAction => ({ type: "user.setPassword", password });
export const setPasswordValid = (valid: boolean): UserAction => ({ type: "user.setPasswordValid", valid });
export const confirmPassword = (password: string): UserAction => ({ type: "user.confirmPassword", password });
export const confirmPasswordValid = (valid: boolean): UserAction => ({ type: "user.confirmPasswordValid", valid });

export const getPhoneCodes = (): ThunkAction => (dispatch, getState) => {
	const { user: { phoneCodes: { loaded } } } = getState();
	if (loaded) {
		return
	}
	dispatch(apiRequest<UserPhoneCodeDictionary>(({ }) => ({}), { url: "/user/phoneCodes", method: "GET" },
		(phoneCodes): UserAction => ({ type: "user.getPhoneCodes", phoneCodes }),
		undefined,
		(): UserAction => ({ type: "user.getPhoneCodes", phoneCodes: [] }),
	));
};

export const register = (): ThunkAction => apiRequest<{ uuid: string }>(({ user: { country } }) => ({ country }), { url: "/user/register" },
	({ uuid }) => (dispatch) => {
		dispatch({ type: "user.register", uuid: uuid });
		dispatch({ type: "common.goNext" });
	},
);

export const saveEmail = (): ThunkAction => apiRequest<{}>(({ user: { email } }) => ({ email }), { url: "/user/email/save" },
	() => (dispatch) => {
		dispatch({ type: "user.saveEmail" });
		dispatch({ type: "common.goNext" });
	},
)

export const resendEmailCode = (): ThunkAction => apiRequest<{}>(({ }) => ({}), { url: "/user/email/resend" })

export const confirmEmail = (): ThunkAction => apiRequest<{}>(({ user: { emailCode } }) => ({ emailCode }), { url: "/user/email/confirm" },
	() => (dispatch) => {
		dispatch({ type: "user.confirmEmail" });
		dispatch({ type: "common.goNext" });
	},
)

export const savePhone = (): ThunkAction => apiRequest<{}>(({ user: { phone, phonePrefix } }) => ({ phone, phonePrefix }), { url: "/user/phone/save" },
	() => (dispatch) => {
		dispatch({ type: "user.savePhone" });
		dispatch({ type: "common.goNext" });
	},
)

export const resendPhoneCode = (): ThunkAction => apiRequest<{}>(({ }) => ({}), { url: "/user/phone/resend" })

export const confirmPhone = (): ThunkAction => apiRequest<{}>(({ user: { phoneCode } }) => ({ phoneCode }), { url: "/user/phone/confirm" },
	() => (dispatch) => {
		dispatch({ type: "user.confirmPhone" });
		dispatch({ type: "common.goNext" });
	},
)

export const savePassword = (): ThunkAction => (dispatch, getState) => {
	const { user: { password, passwordConfirm, passwordValid }} = getState();
	if (!passwordValid || password !== passwordConfirm) {
		dispatch(rejectRequest({message: "Invalid password"}));
		return;
	}
	dispatch(apiRequest<{}>(({ user: { password } }) => ({ password }), { url: "/user/password/save" },
		() => (dispatch) => {
			dispatch({ type: "user.savePassword" });
			dispatch({ type: "common.goNext" });
		},
	));
};