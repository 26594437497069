import { Select as BaseSelect, SelectProps as BaseSelectProps } from "antd";
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { ClearSelectIcon } from "../../utils/styles/common";
import notFoundContentImage from "../../assets/images/registration/not-found-content.png";
import { useAdaptive } from '../ui/Adaptive';
import styles from "../Registration/NotFoundContent/index.module.scss";

const NotFoundContent = () => (
	<div className={styles.notFoundContentContainer}>
		<img className={styles.notFoundContentImage} src={notFoundContentImage} alt="" />
		<p>No result found</p>
	</div>
);

const { Option } = BaseSelect;

export type SelectProps<ValueType, OptionType extends BaseOptionType> = {ns: string, forwardRef: React.RefObject<any>} & BaseSelectProps<ValueType, OptionType | DefaultOptionType>;

const Select = <ValueType, OptionType extends BaseOptionType>({ ns, forwardRef, ...props }: SelectProps<ValueType, OptionType>) => (
	<BaseSelect
		allowClear={true}
		className={ns + "-select"}
		clearIcon={ClearSelectIcon}
		listHeight={useAdaptive() ? 700: 300}
		notFoundContent={<NotFoundContent />}
		popupClassName={ns + "-select-dropdown"}
		ref={forwardRef}
		showArrow={false}
		showSearch={true}
		{...props}
	/>
);

export type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
export { Option };
export default Select;