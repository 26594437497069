import connect, { businessOptions, BusinessOption, BusinessOptionSet, setOption, saveOptions, goBack } from '../../../api';
import { UIEvent, useState } from "react";
import Section from '../../ui/Section';
import commonStyles from "../registration.module.scss";
import styles from "./index.module.scss";

type BusinessSelectProps = {
	options: BusinessOptionSet,
	setOption: (option: BusinessOption, unset: boolean) => void,
	saveOptions: () => void,
	goBack: () => void,
};

const BusinessSelect = ({ options, setOption, saveOptions, goBack }: BusinessSelectProps) => {
	const [shadowMobile, setShadowMobile] = useState<boolean>(true);
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setOption(event.target.value as BusinessOption, event.target.checked);
	const isChecked = (option: BusinessOption) => options[option] ? styles.labelActive : styles.labelPassive;
	const onScroll = (event: UIEvent<HTMLFormElement>) => {
		let scrollElement = event.currentTarget.scrollTop;
		if (scrollElement < event.currentTarget.offsetHeight * 0.3) {
			setShadowMobile(true);
		} else {
			setShadowMobile(false);
		}
	}
	return (
		<Section className={`${commonStyles.register} ${shadowMobile ? styles.boxShadowMobile : ''}`} title="What would you like to do with FBC Business?" label="Continue" onSubmit={saveOptions} onScroll={onScroll} description={''}>
			<div className={styles.registerFormContainer}>
				<div className={styles.registerForm}>
					{businessOptions.map(({ option, label }) => (
						<label key={option} className={isChecked(option)} htmlFor={option}>
							<input type="checkbox" onChange={onChange} id={option} value={option} name="businessSelect" />
							{label}
						</label>
					))}
				</div>
			</div>
		</Section>
	);
};

export default connect(
	({ business: { options } }) => ({ options }),
	dispatch => ({
		goBack: () => dispatch(goBack()),
		setOption: (option: BusinessOption, selected: boolean) => dispatch(setOption(option, selected)),
		saveOptions: () => dispatch(saveOptions()),
	}),
)(BusinessSelect);