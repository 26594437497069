import type { Action } from './actions';
import type { AddressState } from './reducers/address';
import type { BusinessState } from './reducers/business';
import type { CommonState } from './reducers/common';
import type { PersonState } from './reducers/person';
import type { UserState } from './reducers/user';
import addressReducer, { addressState } from './reducers/address';
import businessReducer, { businessState } from './reducers/business';
import commonReducer, { commonState } from './reducers/common';
import personReducer, { personState } from './reducers/person';
import userReducer, { userState } from './reducers/user';

export type State = {
	address: AddressState
	business: BusinessState
	common: CommonState
	person: PersonState,
	user: UserState,
};

export const initialState:State = {
	address: addressState,
	business: businessState,
	common: commonState,
	person: personState,
	user: userState,
};

const reducers = {
	address: addressReducer,
	business: businessReducer,
	common: commonReducer,
	person: personReducer,
	user: userReducer,
};

export type ReducerTypes = typeof reducers;

interface StateIterable { [key: string]: any };

const combineReducers = (reducers: ReducerTypes) => {
	return (state: State, action: Action): State => {
		console.log(action);
		let hasChanged = false;
		const reducerKeys = Object.keys(reducers);
		const reducerValues = Object.values(reducers);
		const nextState: State = { ...state };
		for (let i = 0; i < reducerKeys.length; i++) {
			const key = reducerKeys[i];
			const reducer = reducerValues[i];
			const prevStateForKey = (state as StateIterable)[key];
			const nextStateForKey = reducer(prevStateForKey, action);
			if (typeof nextStateForKey === "undefined") {
				throw new Error();
			}
			(nextState as StateIterable)[key] = nextStateForKey;
			hasChanged = hasChanged || nextStateForKey !== prevStateForKey
		}
		return hasChanged ? nextState : state;
	};
};

export const rootReducer = combineReducers(reducers);