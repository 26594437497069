import { Input, InputProps } from 'antd';
import Label from './Label';

type InputWithLabelProps = {
	ns: string
	label: string
	value: string
	onValueChange: (value: string) => void
} & InputProps;

const InputWithLabel = ({ ns, ...props }: InputWithLabelProps) => <Label Component={Input} {...props} ns={`create-${ns}`} />;

export default InputWithLabel;