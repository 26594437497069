import { useEffect } from 'react';
import connect, { getPhoneCodes, UserPhoneCodeDictionary } from '../../api';
import SelectModal, { SelectModalProps, Option } from './SelectModal';
import CountryIcons from './CountryIcons';

const icons: { [code: string]: string } = CountryIcons;

export type PhoneCodeOption = {
	code: string,
	phoneCode: string,
};

export type PhoneCodeSelectModalProps = {
	loaded: boolean,
	phoneCodes: UserPhoneCodeDictionary,
	getPhoneCodes: () => void,
} & SelectModalProps<string, PhoneCodeOption>;

const PhoneCodeSelectModal = ({ ns, loaded, phoneCodes, getPhoneCodes, ...props }: PhoneCodeSelectModalProps) => {
	useEffect(getPhoneCodes, [getPhoneCodes]);
	const selectionRender: PhoneCodeSelectModalProps["selectionRender"] = (value, option) => (<>
		{icons[option.code] && <img className={`${ns}-country-btn-img`} src={icons[option.code]} alt="" />}
		{value}
	</>);
	return (
		<SelectModal ns={`${ns}-country`} optionLabelProp="label" placeholder="Country" loading={!loaded} disabled={!loaded} selectionRender={selectionRender} {...props}>
			{phoneCodes.map(({ code, phoneCode }, key) => (
				<Option key={key} value={phoneCode} label={phoneCode} code={code}>
					<span className={`${ns}-country-option-full-name`}>
						<span className={ns + "-name"}>
							<img className={`${ns}-country-option-image`} src={icons[code] || ""} alt={phoneCode} />
							{phoneCode}
						</span>
						<span className={`${ns}-country-option-name-index`}>{code}</span>
					</span>
				</Option>
			))}
		</SelectModal>
	);
}

export default connect(
	({ user: { phoneCodes: { data: phoneCodes, loaded } } }) => ({ phoneCodes, loaded }),
	dispatch => ({ getPhoneCodes: () => dispatch(getPhoneCodes()) }),
)(PhoneCodeSelectModal);