import { apiRequest } from '../api';
import type { ThunkAction } from "./index";
import dayjs from 'dayjs';

export type BusinessDate = dayjs.Dayjs | null;

export type BusinessLegalNameDictionary = Array<{
	regNumber: string,
	name: string
}>

export type BusinessTypeDictionary = Array<{
	id: number,
	name: string,
}>;

export type BusinessOption =
	| "receive"
	| "personal"
	| "employees"
	| "currencies"
	| "expenses"
	| "invoice"
	| "salary"

export type BusinessOptionSet = {
	[option in BusinessOption]: boolean;
};

export const businessOptions: Array<{ option: BusinessOption, label: string }> = [
	{ option: "receive", label: "Receive payments from customers" },
	{ option: "personal", label: "Make everyday purchases" },
	{ option: "employees", label: "Pay suppliers and emploeyees" },
	{ option: "currencies", label: "Manage multiple currencies" },
	{ option: "expenses", label: "For expense management" },
	{ option: "invoice", label: "For invoice issuance" },
	{ option: "salary", label: "To pay salaries" },
];

export type BusinessRole = 
	| "director"
	| "directors"
	| "noDirector"

export type BusinessAction =
	| { type: "business.getLegalNames", legalNames: BusinessLegalNameDictionary }
	| { type: "business.getTypes", types: BusinessTypeDictionary }
	| { type: "business.setCountry", country: string }
	| { type: "business.setLegalName", legalName: string }
	| { type: "business.setTradeName", tradeName: string }
	| { type: "business.setRegNumber", regNumber: string }
	| { type: "business.setRegDate", date: BusinessDate }
	| { type: "business.setType", businessType: number }
	| { type: "business.saveBusiness" }
	| { type: "business.setOption", option: BusinessOption, selected: boolean }
	| { type: "business.saveOptions" }
	| { type: "business.setRole", role: BusinessRole }
	| { type: "business.saveRole" }

export const setBusinessCountry = (country: string): BusinessAction => ({ type: "business.setCountry", country });
export const setLegalName = (legalName: string): BusinessAction => ({ type: "business.setLegalName", legalName });
export const setTradeName = (tradeName: string): BusinessAction => ({ type: "business.setTradeName", tradeName });
export const setRegNumber = (regNumber: string): BusinessAction => ({ type: "business.setRegNumber", regNumber });
export const setRegDate = (date: BusinessDate): BusinessAction => ({ type: "business.setRegDate", date });
export const setType = (businessType: number): BusinessAction => ({ type: "business.setType", businessType });
export const setOption = (option: BusinessOption, selected: boolean): BusinessAction => ({ type: "business.setOption", option, selected });
export const setRole = (role: BusinessRole): BusinessAction => ({ type: "business.setRole", role });

export const getLegalNames = (): ThunkAction => (dispatch, getState) => {
	const { business: { legalNames: { loaded } } } = getState();
	if (loaded) {
		return
	}
	dispatch(apiRequest<BusinessLegalNameDictionary>(
		({ }) => ({}),
		{ url: "/business/legalNames", method: "GET" },
		(legalNames): BusinessAction => ({ type: "business.getLegalNames", legalNames }),
		undefined,
		(): BusinessAction => ({ type: "business.getLegalNames", legalNames: [] }),
	));
};

export const getBusinessTypes = (): ThunkAction => (dispatch, getState) => {
	const { business: { types: { loaded } } } = getState();
	if (loaded) {
		return
	}
	dispatch(apiRequest<BusinessTypeDictionary>(
		({ }) => ({}),
		{ url: "/business/types", method: "GET" },
		(types): BusinessAction => ({ type: "business.getTypes", types }),
		undefined,
		(): BusinessAction => ({ type: "business.getTypes", types: [] }),
	));
};

export const saveBusiness = (): ThunkAction => apiRequest<{}>(
	({ business: { country, legalName, tradeName, regNumber, regDate, type } }) => ({ country, legalName, tradeName, regNumber, regDate: regDate?.format("YYYY-MM-DD"), type }),
	{ url: "/business/save" },
	() => (dispatch) => {
		dispatch({ type: "business.saveBusiness" });
		dispatch({ type: "common.goNext" });
	},
)

export const saveOptions = (): ThunkAction => apiRequest<{}>(
	({ business: { options } }) => ({ options: Object.entries(options).filter(([value]) => value).map(([key]) => key) }),
	{ url: "/business/options/save" },
	() => (dispatch) => {
		dispatch({ type: "business.saveOptions" });
		dispatch({ type: "common.goNext" });
	},
)

export const saveRole = (): ThunkAction => apiRequest<{}>(
	({ business: { role } }) => ({ role }),
	{ url: "/business/role/save" },
	() => (dispatch) => {
		dispatch({ type: "business.saveRole" });
	},
)

