import connect, { setPhoneCode, resendPhoneCode, confirmPhone } from '../../../api';
import OtpInput from "../OtpInput/OtpInput";
import Section from '../../ui/Section';
import Timer from '../../ui/Timer';
import phoneOTPImage from "../../../assets/images/registration/phone-otp.webp";
import "./index.scss";

type PhoneOTPProps = {
	phone: string,
	phonePrefix: string,
	phoneCode: string,
	phoneConfirmed: boolean,
	setPhoneCode: (phoneCode: string) => void,
	resendPhoneCode: () => void,
	confirmPhone: () => void,
};

function PhoneOTP({ phone, phonePrefix, phoneCode, setPhoneCode, resendPhoneCode, confirmPhone }: PhoneOTPProps) {
	return (
		<Section title="6-digit code" label="Confirm" image={phoneOTPImage} onSubmit={confirmPhone} description={<p>Please enter the code we've sent to {phonePrefix} {phone}</p>}>
			<OtpInput value={phoneCode} valueLength={6} onChange={setPhoneCode} />
			<Timer ns="phone" onResend={resendPhoneCode} />
		</Section>
	);
};

export default connect(
	({ user: { phone, phonePrefix, phoneCode } }) => ({ phone, phonePrefix, phoneCode }),
	dispatch => ({
		setPhoneCode: (phoneCode) => dispatch(setPhoneCode(phoneCode)),
		resendPhoneCode: () => dispatch(resendPhoneCode()),
		confirmPhone: () => dispatch(confirmPhone()),
	})
)(PhoneOTP);