import { useState, useEffect } from 'react';

const defaultTimeout = 5;

type TimerProps = {
	ns: string
	onResend: () => void,
};

const Timer = ({ ns, onResend }: TimerProps) => {
	const [timer, setTimer] = useState(defaultTimeout);
	const onClick = () => {
		if (!timer) {
			onResend();
			setTimer(defaultTimeout);
		}
	};
	useEffect(() => {
		const interval = setInterval(() => setTimer(timer - (!!timer ? 1 : 0)), 1000);
		return () => clearInterval(interval);
	});
	return (
		<div className={`${ns}-otp-resend-container`}>
			<button type="button" className={!!timer ? `${ns}-otp-resend-btn` : `${ns}-otp-resend-btn ${ns}-otp-resend-btn-active`} disabled={timer !== 0} onClick={onClick}>
				<span>Resend Code </span>
				{!timer || <span>in 00:{timer.toFixed(0).padStart(2, "0")}</span>}
			</button>
		</div>
	);
};


export default Timer;