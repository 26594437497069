import { apiRequest } from '../api';
import type { ThunkAction } from "./index";

export type AddressPostCodeDictionary = Array<{
	postCode: string
	city: string
	address: string
}>;

export type AddressAction =
	| { type: "address.getPostCodes", postCodes: AddressPostCodeDictionary }
	| { type: "address.setCountry", country: string }
	| { type: "address.setCity", city: string }
	| { type: "address.setPostCode", postCode: string }
	| { type: "address.setAddressLine1", addressLine1: string }
	| { type: "address.setAddressLine2", addressLine2: string }
	| { type: "address.saveAddress" }

export const setAddressCountry = (country: string): AddressAction => ({ type: "address.setCountry", country });
export const setCity = (city: string): AddressAction => ({ type: "address.setCity", city });
export const setPostCode = (postCode: string): AddressAction => ({ type: "address.setPostCode", postCode });
export const setAddressLine1 = (addressLine1: string): AddressAction => ({ type: "address.setAddressLine1", addressLine1 });
export const setAddressLine2 = (addressLine2: string): AddressAction => ({ type: "address.setAddressLine2", addressLine2 });

export const getPostCodes = (): ThunkAction => (dispatch, getState) => {
	const { address: { postCodes: { loaded } } } = getState();
	if (loaded) {
		return
	}
	dispatch(apiRequest<AddressPostCodeDictionary>(
		({ }) => ({}),
		{ url: "/address/postCodes", method: "GET" },
		(postCodes): AddressAction => ({ type: "address.getPostCodes", postCodes }),
		undefined,
		(): AddressAction => ({ type: "address.getPostCodes", postCodes: [] }),
	));
};


export const saveAddress = (): ThunkAction => apiRequest<{}>(
	({ address: { country, city, postCode, addressLine1, addressLine2 } }) => ({ country, city, postCode, addressLine1, addressLine2 }),
	{ url: "/address/save" },
	() => (dispatch) => {
		dispatch({ type: "address.saveAddress" });
		dispatch({ type: "common.goNext" });
	},
)
