import { useReducer } from 'react';
import { rootReducer, initialState } from './reducer';
import { StateContext, DispatchContext } from './context';
import { ThunkMiddleware } from './middleware';

export default function Store({ children }: { children: any }) {
	const [state, dispatch] = useReducer(rootReducer, initialState);
	const thunkDispatch = ThunkMiddleware(dispatch, () => state);
	return (
		<DispatchContext.Provider value={thunkDispatch}>
			<StateContext.Provider value={state}>
				{children}
			</StateContext.Provider>
		</DispatchContext.Provider>
	);
}