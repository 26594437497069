import { Dispatch } from 'react';
import { Action, initRequest, resolveRequest, rejectRequest, CommonError } from './actions';
import type { MapStateToProps } from './connect';
import { State } from './reducer';

const baseURL = "https://b.forus.gg";

export type Request = {
	url: string,
	method?: string,
};

export function apiRequest<Type>(
	selector: MapStateToProps,
	request: Request,
	success?: (data: Type) => Action,
	error?: (error: CommonError) => Action,
	pending?: () => Action,
) {
	return (dispatch: Dispatch<Action>, getState: () => State) => {
		dispatch(initRequest());
		if (pending) {
			dispatch(pending());
		}
		fetch(`${baseURL}${request.url}`, {
			method: request.method ? request.method : "POST",
			mode: "cors",
			body: request.method !== "GET" ? JSON.stringify(selector(getState())) : undefined,
			headers: { "Content-Type": "application/json" },
			credentials: "include",
		}).then(response => response.json().then(data => {
			if (response.ok) {
				dispatch(resolveRequest());
				if (success) {
					dispatch(success(data));
				}
			} else {
				dispatch(rejectRequest(data));
				if (error) {
					dispatch(error(data));
				}
			}
		}));
	};
}

