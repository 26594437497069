import connect from '../../api/connect';
import { FormEvent } from 'react';
import { goBack } from '../../api/actions';
import { PrimaryBtn } from "../../utils/styles/common";
import styles from "../Registration/registration.module.scss";
import ErrorModal from './ErrorModal';
import Spinner from './Spinner';

type SectionProps = {
	image: any,
	title: string,
	label: string,
	description: JSX.Element,
	loading: boolean,
	children: JSX.Element[] | JSX.Element,
	className?: string,
	onSubmit: () => void
	onScroll?: () => void,
	goBack: () => void,
};

const Section = ({ image, title, label, description, loading, children, className, onSubmit, onScroll, goBack }: SectionProps) => {
	const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSubmit();
	};
	return (
		<section className={className ? className : styles.register}>
			<button disabled={loading} className={styles.backBtn} onClick={goBack}></button>
			<div className={styles.registerTop}>
				{!!image && <img alt="no-content" src={image} tabIndex={-1} />}
				<h3>{title}</h3>
				{description}
			</div>
			<form className={styles.registerForm} onSubmit={onFormSubmit} onScroll={onScroll}>
				<>{children}</>
				<PrimaryBtn disabled={loading} type="submit">
					{loading ? <Spinner /> : label}
				</PrimaryBtn>
			</form>
			<ErrorModal />
		</section >
	);
};

export default connect(
	({ common: { loading, error } }) => ({ loading, error }),
	dispatch => ({
		goBack: () => dispatch(goBack()),
	}),
)(Section);