import connect from '../../api/connect';
import Welcome from '../../components/Registration/1-Welcome';
import Country from '../../components/Registration/2-Country';
import Email from '../../components/Registration/3-Email';
import EmailOTP from '../../components/Registration/4-EmailOTP';
import Phone from '../../components/Registration/5-Phone';
import PhoneOTP from '../../components/Registration/6-PhoneOTP';
import CreatePassword from '../../components/Registration/7-CreatePassword';
import NameAndBirth from '../../components/Registration/8-NameAndBirth';
import Address from '../../components/Registration/9-Address';
import Business from '../../components/Registration/10-Business';
import BusinessSelect from '../../components/Registration/11-BusinessSelect';
import BusinessRole from '../../components/Registration/12-BusinessRole';

const Registration = ({ step }: { step: number }) => {
	const components = [
		<Welcome />,
		<Country />,
		<Email />,
		<EmailOTP />,
		<Phone />,
		<PhoneOTP />,
		<CreatePassword />,
		<NameAndBirth />,
		<Address />,
		<Business />,
		<BusinessSelect />,
		<BusinessRole />,
	];
	return components[step];
};

export default connect(
	({ common: { step } }) => ({ step }),
	() => ({}), 
)(Registration);