import type { Action, PersonDate } from '../actions';
import { isThunkAction } from '../actions';

export type PersonState = {
	firstName: string,
	lastName: string,
	birthDate: PersonDate,
	saved: boolean,
};

export const personState: PersonState = {
	firstName: "",
	lastName: "",
	birthDate: null,
	saved: false,
};

export default function personReducer(state: PersonState, action: Action): PersonState {
	if (isThunkAction(action)) {
		return state;
	}
	switch (action.type) {
		case "person.setFirstName":
			return { ...state, firstName: action.firstName }
		case "person.setLastName":
			return { ...state, lastName: action.lastName }
		case "person.setBirthDate":
			return { ...state, birthDate: action.birthDate }
		case "person.savePersonal":
			return { ...state, saved: true }
		default:
			return state;
	}
}