import connect, { setCountry, register } from '../../../api';
import Section from '../../ui/Section';
import CountrySelectModal from '../../ui/CountrySelectModal';
import countryImage from "../../../assets/images/registration/country.webp";
import "./index.scss";

export const Country = ({ country, setCountry, register }: ({
	country: string,
	setCountry: (country: string) => void,
	register: () => void,
})) => {
	const onSelect = (value: string) => {
		setCountry(value);
	};
	return (
		<Section onSubmit={register} title="Country of incorporation" label="Continue" image={countryImage}
			description={<p style={{ width: '71%' }}>Let us know where your company is incorporated so that we can assist you with the account opening</p>
		}>
			<CountrySelectModal ns="register" label="Select Country" value={country} onSelect={onSelect} />
		</Section>
	);
};

export default connect(
	({ user: { country } }) => ({ country }),
	dispatch => ({
		setCountry: (country) => dispatch(setCountry(country)),
		register: () => dispatch(register()),
	}),
)(Country);

/*
import AdaptiveModal from '../../ui/AdaptiveModal';
	const onCancel = () => {
		setCountry("");
	};
			<AdaptiveModal ns="register" isOpen={isValiableError} onCancel={onCancel}>
				<div className="valiable-country-modal-container">
					<img className="valiable-country-modal-container-image" src={countryImage} alt="Country" />
					<p className="valiable-country-modal-container-text">We are coming soon to {country}</p>
					<button className="valiable-country-modal-container-btn" onClick={onCancel} type="button">
						<span className="valiable-country-modal-container-btn-text">Change Country</span>
					</button>
				</div>
			</AdaptiveModal>
*/