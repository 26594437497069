import { useEffect } from 'react';
import connect, { getPostCodes, AddressPostCodeDictionary } from '../../api';
import SelectModal, { SelectModalProps, Option } from './SelectModal';

export type PostCodeOption = {
	postCode: string,
	city: string,
	address: string,
};

export type PostCodeSelectProps = {
	ns: string,
	loaded: boolean,
	postCodes: AddressPostCodeDictionary,
	getPostCodes: () => void,
} & SelectModalProps<string, PostCodeOption>;

const PostCodeSelectModal = ({ ns, loaded, postCodes, getPostCodes, ...props }: PostCodeSelectProps) => {
	useEffect(getPostCodes, [getPostCodes]);
	return loaded ? (
		<SelectModal ns={ns + "-country"} optionLabelProp="label" placeholder="Postcode" loading={!loaded} disabled={!loaded} {...props}>
			{postCodes.map(({ postCode, city, address }, key) => {
				const label = [address, postCode, city].filter(n => !!n).join(", ");
				return (
					<Option key={key} label={label} value={postCode} postCode={postCode} city={city} address={postCode ? address : ""}>
						<span className={`${ns}-country-option-full-name`}>
							<span className={`${ns}-country-option-name`}>
								{label}
							</span>
						</span>
					</Option>
				);
			})}
		</SelectModal>
	) : null;
};

export default connect(
	({ address: { postCodes: { data: postCodes, loaded } } }) => ({ postCodes, loaded }),
	dispatch => ({ getPostCodes: () => dispatch(getPostCodes()) }),
)(PostCodeSelectModal);