import connect, { setEmail, saveEmail, skipNext } from '../../../api';
import { Link } from "react-router-dom";
import Section from '../../ui/Section';
import Input from '../../ui/Input';
import emailImage from "../../../assets/images/registration/email.webp";
import "./index.scss";

export const Email = ({ email, setEmail, saveEmail, skipNext }: {
	email: string,
	emailSaved: boolean,
	loading: boolean,
	error: { message: string },
	setEmail: (email: string) => void,
	saveEmail: () => void,
	skipNext: () => void
}) => {
	return (
		<Section title="Email" label="Continue" image={emailImage} onSubmit={saveEmail} description={
			<p>Enter the email address you want to use to open an account or login</p>
		}>
			<Input ns="email" label="Email" value={email} onValueChange={setEmail} />
			<div className="create-email-container-text">
				<span>
					By confirming your email, you agree to our
					<Link to="/terms" className="create-email-container-text-link">Terms of Conditions</Link>
					and that you have read and understood our
					<Link to="/policy" className="create-email-container-text-link">Privacy Policy</Link>
				</span>
			</div>
		</Section>
	);
};

export default connect(
	({ user: { email } }) => ({ email }),
	dispatch => ({
		setEmail: (email: string) => dispatch(setEmail(email)),
		saveEmail: () => dispatch(saveEmail()),
		skipNext: () => dispatch(skipNext()),
	}),
)(Email);