import { Dispatch } from 'react';
import { State } from './reducer';
import { StateContext, DispatchContext } from './context';
import type { Action } from './actions';

export interface MapStateToProps {
	(state: State): ({ [key: string]: any });
};

export interface MapDispatchToProps {
	(dispatch: Dispatch<Action>): ({ [action: string]: (...args: any[]) => void });
};

export const Connect = ({ Component, mapState, mapDispatch, ...ownProps }: { Component: any, mapState: MapStateToProps, mapDispatch: MapDispatchToProps, ownProps?: any }) => (
	<DispatchContext.Consumer>
		{dispatch => (<StateContext.Consumer>
			{state => <Component
				{...mapState(state)}
				{...mapDispatch(dispatch)}
				{...ownProps}
			/>}
		</StateContext.Consumer>
		)}
	</DispatchContext.Consumer>
);

export default function connect(mapState?: MapStateToProps, mapDispatch?: MapDispatchToProps) {
	return (Component: any) => (props: any) => <Connect Component={Component} mapState={mapState} mapDispatch={mapDispatch} {...props} />;
};