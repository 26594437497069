import { useEffect } from 'react';
import connect, { getLegalNames, BusinessLegalNameDictionary } from '../../api';
import SelectModal, { SelectModalProps, Option } from './SelectModal';

export type BusinessNameOption = {
	regNumber: string
	name: string
};

export type BusinessNameSelectModalProps = {
	ns: string,
	loaded: boolean,
	legalNames: BusinessLegalNameDictionary,
	getLegalNames: () => void,
} & SelectModalProps<number, BusinessNameOption>;

const BusinessNameSelectModal = ({ ns, loaded, legalNames, getLegalNames, ...props }: BusinessNameSelectModalProps) => {
	useEffect(getLegalNames, [getLegalNames]);
	return (
		<SelectModal ns={ns} optionLabelProp="label" placeholder="Business Name" loading={!loaded} disabled={!loaded} {...props}>
			{legalNames ? legalNames.map(({ regNumber, name }, key) => (
				<Option key={key} label={name} value={regNumber} nname={name} regNumber={regNumber}>
					<span className={`${ns}-option-full-name`}>
						<span className={`${ns}-option-name`}>
							{name}
						</span>
						<span className={`${ns}-option-name-index`}>
							{regNumber}
						</span>
					</span>
				</Option>
			)) : undefined}
		</SelectModal>
	);
};

export default connect(
	({ business: { legalNames: { data: legalNames, loaded } } }) => ({ legalNames, loaded }),
	dispatch => ({ getLegalNames: () => dispatch(getLegalNames()) }),
)(BusinessNameSelectModal);