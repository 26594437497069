import { MouseEvent } from 'react';
import '../Registration/CustomModal/index.scss';

type CustomModalProps = {
	className?: string,
	onCancel: () => void,
	isOpen: boolean,
	children: JSX.Element | JSX.Element[]
};

const CustomModal = ({ className, onCancel, isOpen, children }: CustomModalProps) => {
	const onClick = (e: MouseEvent<HTMLDivElement>) => {
		if (e.currentTarget.classList.contains("registration-modal")) {
			onCancel();
		}
	};
	return (
		<div className={isOpen ? "registration-modal active" : "registration-modal"} onClick={onClick}>
			<div className={className ? className : "registration-modal-content"}>
				{children}
				<div className="ant-modal-close" onClick={onClick}>
					<span className="ant-custom-close-icon"></span>
				</div>
			</div>
		</div >
	);
};

export default CustomModal;