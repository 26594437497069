type ToggleButtonProps = {
	ns: string
	label: React.ReactNode,
	value?: any
	onClick: () => void,
};

const ToggleButton = ({ ns, label, value, onClick }: ToggleButtonProps) => (
	<button className={!value ? `${ns}-btn` : `${ns}-btn ${ns}-btn-active`} onClick={onClick} type="button">
		<div>
			<p className={!value ? `${ns}-btn-text` : `${ns}-btn-text ${ns}-btn-text-active`}>{label}</p>
			<p>{value}</p>
		</div>
	</button>
);

export default ToggleButton;