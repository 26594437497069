import { useEffect } from 'react';
import connect, { getCountries, CommonCountryDictionary } from '../../api';
import SelectModal, { SelectModalProps, Option } from './SelectModal';
import CountryIcons from './CountryIcons';

const icons: { [code: string]: string } = CountryIcons;

export type CountryOption = {
	code: string,
	name: string,
};

export type CountrySelectModalProps = {
	loaded: boolean,
	countries: CommonCountryDictionary,
	getCountries: () => void,
} & SelectModalProps<string, CountryOption>;

const CountrySelectModal = ({ ns, loaded, countries, getCountries, ...props }: CountrySelectModalProps) => {
	useEffect(getCountries, [getCountries]);
	return loaded ? (
		<SelectModal ns={`${ns}-country`} optionLabelProp="label" placeholder="Country" loading={!loaded} disabled={!loaded} {...props}>
			{countries.map(({ code, name, valiable }, key) => (
				<Option key={key} value={code} label={name} valiable={valiable}>
					<span className={`${ns}-country-option-full-name`}>
						<span className={`${ns}-country-option-name`}>
							<img className={`${ns}-country-option-image`} src={icons[code] || ""} alt={name} />
							{name}
						</span>
						<span className={`${ns}-country-option-name-index`}>{code}</span>
					</span>
				</Option>
			))}
		</SelectModal>
	) : null;
}

export default connect(
	({ common: { countries: { data: countries, loaded } } }) => ({ countries, loaded }),
	dispatch => ({ getCountries: () => dispatch(getCountries()) }),
)(CountrySelectModal);