import { useState } from 'react';
import type { DatePickerProps as BaseDatePickerProps } from 'antd';
import { DatePicker as BaseDatePicker } from 'antd';
import { DateIcon, DatePickerNextArrow, DatePickerPrevArrow } from "../../utils/styles/common";
import dayjs from 'dayjs';

export type DatePickerProps = {
	ns: string,
	value: dayjs.Dayjs | null,
	onChange: BaseDatePickerProps["onChange"],
	placeholder: string,
};

const defaultFormat = "YYYY-MM-DD";

const DatePicker = ({ ns, value, onChange, placeholder }: DatePickerProps) => {
	const [isOpen, setOpen] = useState(false);
	return !isOpen && value ? (
		<button className={ns + "-btn-active"} onClick={() => setOpen(true)} type="button">
			<div>
				<p className={ns + "-btn-text-active"}>{placeholder}</p>
				<p>{value.format(defaultFormat)}</p>
			</div>
		</button>
	) : (
		<BaseDatePicker
			className={ns + "-datepicker"}
			format={defaultFormat}
			inputReadOnly={true}
			onChange={onChange}
			onOpenChange={() => setOpen(!isOpen)}
			open={isOpen}
			nextIcon={DatePickerNextArrow}
			placeholder={placeholder}
			popupClassName={"popup-" + ns + "-datepicker"}
			prevIcon={DatePickerPrevArrow}
			showToday={false}
			suffixIcon={DateIcon}
			superNextIcon={DatePickerNextArrow}
			superPrevIcon={DatePickerPrevArrow}
			value={value}
		/>
	);
};

export default DatePicker;