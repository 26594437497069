import ReactDOM from "react-dom/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "@fontsource/poppins";
import "@fontsource/crimson-text";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "./index.css";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

serviceWorker.register();
