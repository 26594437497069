import connect, { goNext } from '../../../api';
import { PrimaryBtn } from "../../../utils/styles/common";
import { useAdaptive } from '../../ui/Adaptive';
import logotype from "../../../assets/icons/registration/logotype.svg";
import logotypeWhite from "../../../assets/icons/registration/logotype-white.svg";
import styles from "./index.module.scss";

const Welcome = ({ goNext }: { goNext: () => void }) => (
	<section className={styles.welcome}>
		<div className={styles.content}>
			<img src={useAdaptive() ? logotypeWhite : logotype} alt="Logotype Forus Capital Bank" />
			<div className={styles.middleContent}>
				<span>Welcome to</span>
				<h1>FCB Business</h1>
				<p>FCB helps you save time and money, while giving you total control over your finances.</p>
			</div>
			<div className={styles.bottomContent}>
				<article>
					<p>You will get</p>
					<ul>
						<li>An Account in your own name</li>
						<li>Two personal cards in your name</li>
						<li>Access for your accountant</li>
						<li>Two personal cards in your name</li>
						<li>Access for your accountant</li>
					</ul>
				</article>
				<PrimaryBtn onClick={() => goNext()}>Continue</PrimaryBtn>
			</div>
		</div>
		<div className={styles.background}></div>
	</section>
);

export default connect(({ }) => ({}), dispatch => ({ goNext: () => dispatch(goNext()) }))(Welcome);