import connect, { setAddressCountry, setCity, setPostCode, setAddressLine1, setAddressLine2, saveAddress } from '../../../api';
import { useState } from "react";
import Section from '../../ui/Section';
import CountrySelectModal from '../../ui/CountrySelectModal';
import PostCodeSelectModal, { PostCodeOption } from '../../ui/PostCodeSelectModal';
import Input from '../../ui/Input';
import addressImage from "../../../assets/images/registration/address.webp";
import "./index.scss";

type AddressProps = {
	country: string
	city: string
	postCode: string
	addressLine1: string
	addressLine2: string,
	setCountry: (country: string) => void,
	setCity: (city: string) => void,
	setPostCode: (postCode: string) => void,
	setAddressLine1: (addressLine1: string) => void,
	setAddressLine2: (addressLine2: string) => void,
	saveAddress: () => void,
};

export const Address = ({ country, city, postCode, addressLine1, addressLine2, setCountry, setCity, setPostCode, setAddressLine1, setAddressLine2, saveAddress }: AddressProps) => {
	const [isCountry, setIsCountry] = useState(false);
	const [isPostCode, setIsPostCode] = useState(false);
	const onSelectCountry = (value: string) => {
		if (!value) {
			setCountry("");
			setCity("");
			setPostCode("");
			setAddressLine1("");
			setIsCountry(true);
			setIsPostCode(false);
		} else {
			setCountry(value);
			setIsCountry(true);
			setIsPostCode(false);
		}
	};
	const onSelectPostCode = (value: string, option: PostCodeOption) => {
		setPostCode(value);
		if (value) {
			setCity(option.city);
			setAddressLine1(option.address);
		} else {
			setCity("");
			setAddressLine1("");
		}
		setIsPostCode(true);
	}
	return (
		<Section title="Personal address" label="Continue" image={addressImage} onSubmit={saveAddress} description={<p>We need your home address to open your account</p>}>
			<div className="register-form-container">
				<CountrySelectModal ns="address" label="Country of residence" value={country} onSelect={onSelectCountry} />
				{!isCountry || <PostCodeSelectModal ns="postcode" label="Search postcode" onSelect={onSelectPostCode} />}
				{!isPostCode || (
					<div className="create-address-block">
						<Input ns="address" label="Address line 1" value={addressLine1} onValueChange={setAddressLine1} />
						<Input ns="address" label="Address line 2" value={addressLine2} onValueChange={setAddressLine2} />
						<p className='create-address-optional'>Optional</p>
						<Input ns="address" label="City" value={city} onValueChange={setCity} />
						<Input ns="address" label="Postcode" value={postCode} onValueChange={setPostCode} />
					</div>
				)}
			</div>
		</Section>
	);
};

export default connect(
	({ address: { country, city, postCode, addressLine1, addressLine2 } }) => ({ country, city, postCode, addressLine1, addressLine2 }),
	dispatch => ({
		setCountry: (country: string) => dispatch(setAddressCountry(country)),
		setCity: (city: string) => dispatch(setCity(city)),
		setPostCode: (postCode: string) => dispatch(setPostCode(postCode)),
		setAddressLine1: (address: string) => dispatch(setAddressLine1(address)),
		setAddressLine2: (address: string) => dispatch(setAddressLine2(address)),
		saveAddress: () => dispatch(saveAddress()),
	})
)(Address);