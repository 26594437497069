import { useMemo } from "react";
import styles from "./index.module.scss";

export type Props = {
  value: string;
  valueLength: number;
  onChange: (value: string) => void;
};

const RE_DIGIT = new RegExp(/^\d+$/);

export default function OtpInput({ value, valueLength, onChange }: Props) {
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      };
    };

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    if (nextElementSibling) nextElementSibling.focus();
  };

  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
    if (previousElementSibling) previousElementSibling.focus();
  };

  const handleOnchange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") return;
    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;
    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== "") return;
    targetValue = isTargetValueDigit ? targetValue : " ";

    if (targetValue.length === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);
      onChange(newValue);
      if (!isTargetValueDigit) return;
      focusToNextInput(target);
    } else if (targetValue.length === valueLength) {
      onChange(targetValue);
      target.blur();
    };
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    };
    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    };

    const targetValue = target.value;

    if (e.key !== "Backspace" || targetValue !== "") return;
    focusToPrevInput(target);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null;
    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  // const handleOnPaste = (e: any) => {
  //   let pastedData = e.clipboardData
  //     .getData("text/plain")
  //     .split("")
  //     .filter((num: any) => /[.0-9]/g.test(num))
  //     .slice(0, 6)
  //     .toString()
  //     .replaceAll(",", "")
  //   console.log(pastedData);
  // };

  return (
    <div className={styles.otpGroup}>
      {valueItems.map((digit, idx) => (
        <input
          key={idx + "otp"}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className={styles.otpInput}
          value={digit}
          onChange={(e) => handleOnchange(e, idx)}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          // onPaste={handleOnPaste}
        />
      ))}
    </div>
  );
}
