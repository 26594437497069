import connect, { CommonError, clearError } from '../../api';
import { useState, useEffect } from 'react';
import { Modal } from "antd";
import { ErrorBtn } from "../../utils/styles/common";
import CustomModal from "./CustomModal";
import Adaptive from './Adaptive';
import errorImage from "../../assets/images/registration/error.png";
import styles from "../Registration/ErrorModal/index.module.scss";

export type ErrorModalProps = {
	error: CommonError,
	clearError: () => void,
};

const ErrorModal = ({ error, clearError }: ErrorModalProps) => {
	const [open, setOpen] = useState(false);
	const closeModal = () => {
		setOpen(false);
		clearError();
	}
	useEffect(() => {
		if (error) {
			setOpen(true);
		}
	}, [error]);
	return (
		<Adaptive mobile={children => (
			<CustomModal className="registration-modal-content-error" onCancel={closeModal} isOpen={open}>{children}</CustomModal>
		)} desktop={children => (
			<Modal closable={false} open={open} footer={null}>{children}</Modal>
		)}>
			<>
				<div className={styles.errorContainer}>
					<img className={styles.errorImage} src={errorImage} alt="error" />
					<p className={styles.errorText}>{error?.message}</p>
				</div>
				<ErrorBtn onClick={closeModal}> Try Again </ErrorBtn>
			</>
		</Adaptive>
	);
};

export default connect(
	({ common: { error } }) => ({ error }),
	dispatch => ({
		clearError: () => dispatch(clearError()),
	}),
)(ErrorModal);