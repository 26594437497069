import type { Action, BusinessDate, BusinessLegalNameDictionary, BusinessTypeDictionary, BusinessOptionSet, BusinessRole } from '../actions';
import { isThunkAction } from '../actions';

export type BusinessState = {
	country: string
	legalName: string
	tradeName: string
	regNumber: string
	regDate: BusinessDate
	type: number,
	options: BusinessOptionSet,
	role: BusinessRole,
	legalNames: {
		data: BusinessLegalNameDictionary,
		loaded: boolean,
	},
	types: {
		data: BusinessTypeDictionary,
		loaded: boolean,
	},
	saved: boolean
	optionsSaved: boolean
	roleSaved: boolean
};

export const businessState: BusinessState = {
	country: "",
	legalName: "",
	tradeName: "",
	regNumber: "",
	regDate: null,
	type: 0,
	options: {
		receive: false,
		personal: false,
		employees: false,
		currencies: false,
		expenses: false,
		invoice: false,
		salary: false,
	},
	role: "director",
	legalNames: {
		data: [],
		loaded: false,
	},
	types: {
		data: [],
		loaded: false,
	},
	saved: false,
	optionsSaved: false,
	roleSaved: false,
};

export default function businessReducer(state: BusinessState, action: Action): BusinessState {
	if (isThunkAction(action)) {
		return state;
	}
	switch (action.type) {
		case "business.getLegalNames":
			return { ...state, legalNames: { data: action.legalNames, loaded: true } };
		case "business.getTypes":
			return { ...state, types: { data: action.types, loaded: true } };
		case "business.setCountry":
			return { ...state, country: action.country };
		case "business.setLegalName":
			return { ...state, legalName: action.legalName };
		case "business.setTradeName":
			return { ...state, tradeName: action.tradeName };
		case "business.setRegNumber":
			return { ...state, regNumber: action.regNumber };
		case "business.setRegDate":
			return { ...state, regDate: action.date };
		case "business.setType":
			return { ...state, type: action.businessType };
		case "business.saveBusiness":
			return { ...state, saved: true };
		case "business.setOption":
			return { ...state, options: { ...state.options, [action.option]: action.selected } };
		case "business.saveOptions":
			return { ...state, optionsSaved: true };
		case "business.setRole":
			return { ...state, role: action.role };
		case "business.saveRole":
			return { ...state, roleSaved: true };
		default:
			return state;
	}
}