import { useState, ChangeEvent } from 'react';
import { Input, InputProps } from 'antd';

export type LabelProps = {
	ns: string
	label: string
	value: string
	Component: any
	onValueChange: (value: string) => void
	onFocusChange?: (focus: boolean) => void
} & InputProps;

const Label = ({ ns, label, value, Component = Input, onValueChange, onFocusChange, ...props }: LabelProps) => {
	const [hasFocus, setFocus] = useState(false);
	const onChange = (e: ChangeEvent<HTMLInputElement>) => onValueChange(e.currentTarget.value);
	const onFocus = () => {
		setFocus(true);
		if (onFocusChange) {
			onFocusChange(true);
		}
	};
	const onBlur = () => {
		setFocus(false);
		if (onFocusChange) {
			onFocusChange(false);
		}
	};
	const isActive = hasFocus || !!value;
	return (
		<label className={`${ns}-container`}>
			<Component className={`${ns}-input`} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} {...props} />
			<div className={isActive ? `${ns}-label ${ns}-label-active` : `${ns}-label`}>{label}</div>
		</label>
	);
};

export default Label;