import type { Dispatch } from 'react';
import type { State } from '../reducer';
import type { AddressAction } from './address';
import type { BusinessAction } from './business';
import type { CommonAction } from './common';
import type { PersonAction } from './person';
import type { UserAction } from './user';

export type ThunkAction = (dispatch:Dispatch<Action>, getState:() => State) => void;

export function isThunkAction(action: Action | ThunkAction): action is ThunkAction {
	return !("type" in action);
}

export type Action =
	| AddressAction
	| BusinessAction
	| CommonAction
	| PersonAction
	| UserAction
	| ThunkAction

export * from './address';
export * from './business';
export * from './common';
export * from './person';
export * from './user';