import type { Action } from '../actions';
import { isThunkAction } from '../actions';
import type { CommonCountryDictionary, CommonError } from '../actions/common';

export type CommonState = {
	step: number,
	countries: {
		data: CommonCountryDictionary,
		loaded: boolean,
	},
	loading: boolean,
	error?: CommonError,
};

export const commonState: CommonState = {
	step: 0,
	countries: {
		data: [],
		loaded: false,
	},
	loading: false,
	error: undefined,
};

export default function commonReducer(state: CommonState, action: Action): CommonState {
	if (isThunkAction(action)) {
		return state;
	}
	switch (action.type) {
		case "common.goNext":
			return { ...state, step: state.step + 1 };
		case "common.goBack":
			return { ...state, step: state.step - 1 };
		case "common.skipNext":
			return { ...state, step: state.step + 2 };
		case "common.initRequest":
			return { ...state, loading: true };
		case "common.resolveRequest":
			return { ...state, loading: false };
		case "common.rejectRequest":
			return { ...state, loading: false, error: action.error };
		case "common.clearError":
			return { ...state, error: undefined };
		case "common.getCountries":
			return { ...state, countries: { loaded: true, data: action.countries } };
		default:
			return state;
	}
}