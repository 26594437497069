import { apiRequest } from '../api';
import { ThunkAction } from "./index";

export type CommonCountryDictionary = Array<{
	code: string
	name: string
	valiable: boolean
}>;

export type CommonError = { message: string };

export type CommonAction =
	| { type: "common.getCountries", countries: CommonCountryDictionary }
	| { type: "common.initRequest" }
	| { type: "common.resolveRequest" }
	| { type: "common.rejectRequest", error: CommonError }
	| { type: "common.clearError" }
	| { type: "common.goNext" }
	| { type: "common.skipNext" }
	| { type: "common.goBack" }

export const initRequest = (): CommonAction => ({ type: "common.initRequest" });
export const resolveRequest = (): CommonAction => ({ type: "common.resolveRequest" });
export const rejectRequest = (error: CommonError): CommonAction => ({ type: "common.rejectRequest", error });
export const clearError = (): CommonAction => ({ type: "common.clearError" });
export const goNext = (): CommonAction => ({ type: "common.goNext" });
export const skipNext = (): CommonAction => ({ type: "common.skipNext" });
export const goBack = (): CommonAction => ({ type: "common.goBack" });

export const getCountries = (): ThunkAction => (dispatch, getState) => {
	const { common: { countries: { loaded } } } = getState();
	if (loaded) {
		return
	}
	dispatch(apiRequest<CommonCountryDictionary>(({ }) => ({}), { url: "/common/countries", method: "GET" },
		(countries): CommonAction => ({ type: "common.getCountries", countries }),
		undefined,
		(): CommonAction => ({ type: "common.getCountries", countries: [] }),
	));
};