import connect, { setEmailCode, resendEmailCode, confirmEmail } from '../../../api';
import OtpInput from "../OtpInput/OtpInput";
import Section from '../../ui/Section';
import Timer from '../../ui/Timer';
import emailOTPImage from "../../../assets/images/registration/email-otp.webp";
import "./index.scss";

const EmailOTP = ({ email, emailCode, setEmailCode, resendEmailCode, confirmEmail }: {
	email: string,
	emailCode: string,
	setEmailCode: (emailCode: string) => void,
	resendEmailCode: () => void,
	confirmEmail: () => void,
}) => {
	const onChange = (value: string) => setEmailCode(value);
	return (
		<Section title="We've sent you an email" label="Continue" image={emailOTPImage} onSubmit={confirmEmail} description={<p>Please enter the code we've sent to {email}</p>}>
			<OtpInput value={emailCode} valueLength={6} onChange={onChange} />
			<Timer ns="email" onResend={resendEmailCode} />
		</Section>
	);
};

export default connect(
	({ user: { email, emailCode } }) => ({ email, emailCode }),
	dispatch => ({
		setEmailCode: (emailCode) => dispatch(setEmailCode(emailCode)),
		resendEmailCode: () => dispatch(resendEmailCode()),
		confirmEmail: () => dispatch(confirmEmail()),
	}),
)(EmailOTP);