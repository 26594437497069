import type { Dispatch } from 'react';
import type { Action } from './actions';
import { isThunkAction } from './actions';
import type { State } from './reducer';

export function ThunkMiddleware(oldDispatch: Dispatch<Action>, getState: () => State): Dispatch<Action> {
	const dispatch = function(action: Action) {
		if (isThunkAction(action)) {
			action(dispatch, getState);
		} else {
			oldDispatch(action);
		}
	}
	return dispatch;
}