import connect, { setFirstName, setLastName, setBirthDate, savePersonal, PersonDate } from '../../../api';
import Section from '../../ui/Section';
import Input from '../../ui/Input';
import DatePicker from '../../ui/DatePicker';
import nameAndBirthImage from "../../../assets/images/registration/name-and-birth.webp";
import "./index.scss";

type NameAndBirthProps = {
	firstName: string
	lastName: string
	birthDate: PersonDate,
	setFirstName: (firstName: string) => void,
	setLastName: (lastName: string) => void,
	setBirthDate: (date: PersonDate) => void,
	savePersonal: () => void,
};

export const NameAndBirth = ({ firstName, lastName, birthDate, setFirstName, setLastName, setBirthDate, savePersonal }: NameAndBirthProps) => (
	<Section title="Your name and birth" label="Continue" image={nameAndBirthImage} onSubmit={savePersonal} description={<p>As stated on your official ID. You must be 18 or older to open FCB account</p>}>
		<div className="create-address-block">
			<Input ns="address" label="First Name" value={firstName} onValueChange={setFirstName} maxLength={32} />
			<Input ns="address" label="Last Name" value={lastName} onValueChange={setLastName} maxLength={32} />
			<DatePicker ns="name-and-birth" value={birthDate} onChange={setBirthDate} placeholder="Date of birth" />
		</div>
	</Section>
);

export default connect(
	({ person: { firstName, lastName, birthDate }, }) => ({ firstName, lastName, birthDate }),
	dispatch => ({
		setFirstName: (firstName) => dispatch(setFirstName(firstName)),
		setLastName: (lastName) => dispatch(setLastName(lastName)),
		setBirthDate: (birthDate) => dispatch(setBirthDate(birthDate)),
		savePersonal: () => dispatch(savePersonal()),
	})
)(NameAndBirth);