import styled from "styled-components";
import close from "../../assets/images/registration/close.webp";

// Buttons
export const PrimaryBtn = styled.button`
  width: 350px;
  height: 50px;
  min-height: 50px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: var(--white);
  background-color: var(--green-first);
  border: none;
  border-radius: 10px;

  &:disabled {
    opacity: 0.5;
  }
`;

export const ErrorBtn = styled.button`
  width: 109px;
  margin: 0 auto;
  padding: 9px 14px 9px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--button);
  color: var(--green-first);
  background-color: var(--gray-first);
  border: none;
  border-radius: 100px;

  &:disabled {
    opacity: 0.5;
  }

  @media (max-width:767px) {
    margin: 0;
  }
`;

// Icons
export const ClearSelectIcon = <img style={{width: '18px', height: '18px'}} src={close} alt="close" />
export const CloseModalIcon = <span className={'ant-custom-close-icon'}></span>

// Date Picker
export const DateIcon = <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.25 7.79167V16.2708C19.25 17.9162 17.9162 19.25 16.2708 19.25H5.72917C4.08382 19.25 2.75 17.9162 2.75 16.2708V7.79167H19.25ZM6.64583 13.75C6.01301 13.75 5.5 14.263 5.5 14.8958C5.5 15.5287 6.01301 16.0417 6.64583 16.0417C7.27866 16.0417 7.79167 15.5287 7.79167 14.8958C7.79167 14.263 7.27866 13.75 6.64583 13.75ZM11 13.75C10.3672 13.75 9.85417 14.263 9.85417 14.8958C9.85417 15.5287 10.3672 16.0417 11 16.0417C11.6328 16.0417 12.1458 15.5287 12.1458 14.8958C12.1458 14.263 11.6328 13.75 11 13.75ZM6.64583 9.625C6.01301 9.625 5.5 10.138 5.5 10.7708C5.5 11.4037 6.01301 11.9167 6.64583 11.9167C7.27866 11.9167 7.79167 11.4037 7.79167 10.7708C7.79167 10.138 7.27866 9.625 6.64583 9.625ZM11 9.625C10.3672 9.625 9.85417 10.138 9.85417 10.7708C9.85417 11.4037 10.3672 11.9167 11 11.9167C11.6328 11.9167 12.1458 11.4037 12.1458 10.7708C12.1458 10.138 11.6328 9.625 11 9.625ZM15.3542 9.625C14.7213 9.625 14.2083 10.138 14.2083 10.7708C14.2083 11.4037 14.7213 11.9167 15.3542 11.9167C15.987 11.9167 16.5 11.4037 16.5 10.7708C16.5 10.138 15.987 9.625 15.3542 9.625ZM16.2708 2.75C17.9162 2.75 19.25 4.08382 19.25 5.72917V6.41667H2.75V5.72917C2.75 4.08382 4.08382 2.75 5.72917 2.75H16.2708Z" fill="#CCCCCC"/></svg>

export const DatePickerNextArrow = <svg className="next-arrow-date-picker" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.0293 9.69137L8.50389 4.33329C8.15912 3.99895 7.5 4.20161 7.5 4.64196L7.5 15.3581C7.5 15.7985 8.15911 16.0011 8.50389 15.6668L14.0293 10.3087C14.2125 10.1311 14.2125 9.86899 14.0293 9.69137Z" fill="black"/>
</svg>

export const DatePickerPrevArrow = <svg className="prev-arrow-date-picker" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.97071 10.3086L11.4961 15.6667C11.8409 16.001 12.5 15.7984 12.5 15.358L12.5 4.64188C12.5 4.20153 11.8409 3.99887 11.4961 4.33321L5.97071 9.69129C5.78754 9.86891 5.78754 10.131 5.97071 10.3086Z" fill="black"/>
                            </svg>
