import { useState, useMemo } from 'react';
import { Input } from 'antd';
import type { PasswordProps } from 'antd/lib/input/Password';
import Label from './Label';

const checkList: Array<{ regexp: RegExp, message: string }> = [
	{ regexp: /.{8,}/, message: "8 or more characters" },
	{ regexp: /[0-9]+/, message: "Numbers" },
	{ regexp: /[a-zA-Z]+/, message: "Letters" },
];

type PasswordWithLabelProps = {
	label: string
	value: string
	onValueChange: (value: string) => void
	onPasswordCheck: (isValid: boolean) => void
} & PasswordProps;

const checkPassword = (value: string) => checkList.map(({ regexp, message }) => ({ isValid: regexp.test(value), message }));

const PasswordWithLabel = ({ value, onValueChange, onPasswordCheck, ...props }: PasswordWithLabelProps) => {
	const [hasFocus, setFocus] = useState(false);
	const results = useMemo(() => checkPassword(value), [value]);
	const onRawValueChange = (value: string) => {
		onValueChange(value);
		const isValid = checkPassword(value).reduce((prev, { isValid }) => (prev && isValid), true);
		onPasswordCheck(isValid);
	};
	return (
		<>
			<Label ns="create-pwd" value={value} Component={Input.Password} onValueChange={onRawValueChange} onFocusChange={setFocus} {...props} />
			{hasFocus && (
				<div className="create-pwd-check">
					<p className="create-pwd-check-title">Your password must have</p>
					{results.map(({ isValid, message }, n) => <div key={n} className={isValid ? "create-pwd-check-item-active" : "create-pwd-check-item"}>{message}</div>)}
				</div>
			)}
		</>
	);
}

export default PasswordWithLabel;