import connect, { setPhone, setPhonePrefix, savePhone } from '../../../api';
import Section from '../../ui/Section';
import PhoneCodeSelectModal from '../../ui/PhoneCodeSelectModal';
import Input from '../../ui/Input';
import phoneImage from "../../../assets/images/registration/phone.webp";
import "./index.scss";

export const Phone = ({ phone, phonePrefix, setPhone, setPhonePrefix, savePhone }: {
	phone: string,
	phonePrefix: string,
	setPhone: (phone: string) => {},
	setPhonePrefix: (phonePrefix: string) => {},
	savePhone: () => {},
}) => {
	return (
		<Section title="Phone number" label="Send code" image={phoneImage} onSubmit={savePhone} description={<p>Enter your phone number. We will send you a confirmation code</p>}>
			<div className="create-phone-block">
				<PhoneCodeSelectModal ns="phone" value={phone} onSelect={setPhonePrefix} />
				<Input ns="phone" label="Phone" value={phone} onValueChange={setPhone} type="tel" />
			</div>
		</Section>
	);
};

export default connect(
	({ user: { phone, phonePrefix } }) => ({ phone, phonePrefix }),
	dispatch => ({
		setPhone: (phone) => dispatch(setPhone(phone)),
		setPhonePrefix: (phonePrefix) => dispatch(setPhonePrefix(phonePrefix)),
		savePhone: () => dispatch(savePhone()),
	})
)(Phone);