import Store from './api/store';
import { useEffect } from "react";
import checkWindowHeight from "./utils/functions/checkWindowHeight";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Registration from "./pages/Registration";
import { TermsOfConditionsand } from "./pages/TermsOfConditionsand/TermsOfConditionsand";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
import 'antd/dist/reset.css';

const App = () => {
	useEffect(() => checkWindowHeight(), []);
	const routes = [
		{ path: "/terms", element: <TermsOfConditionsand /> },
		{ path: "/policy", element: <PrivacyPolicy /> },
		{ path: "/", element: <Registration /> },
	];
	const router = createBrowserRouter(routes);
	return <Store><RouterProvider router={router} /></Store>;
};

export default App;
