import connect from './connect';
import { StateContext, DispatchContext } from './context';
import Store from './store';

export * from './actions';
export {
	connect,
	StateContext,
	DispatchContext,
	Store,
};

export default connect;