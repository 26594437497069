import type { Action, AddressPostCodeDictionary } from '../actions';
import { isThunkAction } from '../actions';

export type AddressState = {
	country: string
	city: string
	postCode: string
	addressLine1: string
	addressLine2: string
	postCodes: {
		data: AddressPostCodeDictionary,
		loaded: boolean,
	},
	saved: boolean
};

export const addressState: AddressState = {
	country: "",
	city: "",
	postCode: "",
	addressLine1: "",
	addressLine2: "",
	postCodes: {
		data: [],
		loaded: false,
	},
	saved: false,
};

export default function addressReducer(state: AddressState, action: Action): AddressState {
	if (isThunkAction(action)) {
		return state;
	}
	switch (action.type) {
		case "address.getPostCodes":
			return { ...state, postCodes: { loaded: true, data: action.postCodes } };
		case "address.setCountry":
			return { ...state, country: action.country };
		case "address.setCity":
			return { ...state, city: action.city };
		case "address.setPostCode":
			return { ...state, postCode: action.postCode };
		case "address.setAddressLine1":
			return { ...state, addressLine1: action.addressLine1 }
		case "address.setAddressLine2":
			return { ...state, addressLine2: action.addressLine2 }
		case "address.saveAddress":
			return { ...state, saved: true };
		default:
			return state;
	}
}