import { useMediaQuery } from "react-responsive";

const useAdaptive = () => useMediaQuery({ query: "(max-width: 767px)" });

type AdaptiveProps = {
	mobile: (children: JSX.Element[] | JSX.Element) => JSX.Element,
	desktop: (children: JSX.Element[] | JSX.Element) => JSX.Element,
	children: JSX.Element[] | JSX.Element,
};

const Adaptive = ({ mobile, desktop, children }: AdaptiveProps) => useAdaptive() ? mobile(children) : desktop(children)

export { useAdaptive };
export default Adaptive;