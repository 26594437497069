import { useEffect } from 'react';
import connect, { getBusinessTypes, BusinessTypeDictionary } from '../../api';
import SelectModal, { SelectModalProps, Option } from './SelectModal';

export type BusinessTypeOption = {
	id: number
	name: string
};

export type BusinessTypeSelectProps = {
	ns: string,
	loaded: boolean,
	types: BusinessTypeDictionary,
	getTypes: () => void,
} & SelectModalProps<number, BusinessTypeOption>;

const BusinessTypeSelectModal = ({ ns, loaded, types, getTypes, ...props }: BusinessTypeSelectProps) => {
	useEffect(getTypes, [getTypes]);
	return (
		<SelectModal ns={ns} optionLabelProp="label" placeholder="Business Type" loading={!loaded} disabled={!loaded} {...props}>
			{types.map(({ id, name }, key) => (
				<Option key={key} label={name} value={id}>
					<span className={`${ns}-option-full-name`}>
						<span className={`${ns}-option-name`}>
							{name}
						</span>
					</span>
				</Option>
			))}
		</SelectModal>
	);
};

export default connect(
	({ business: { types: { data: types, loaded } } }) => ({ types, loaded }),
	dispatch => ({ getTypes: () => dispatch(getBusinessTypes()) }),
)(BusinessTypeSelectModal);