import AF from "../../assets/icons/registration/countries/afghanistan.svg"; // Afghanistan
import AX from "../../assets/icons/registration/countries/alandIslands.svg"; // Aland Islands
import AL from "../../assets/icons/registration/countries/albania.svg"; // Albania
import DZ from "../../assets/icons/registration/countries/algeria.svg"; // Algeria
import AS from "../../assets/icons/registration/countries/americanSamoa.svg"; // American Samoa
import AD from "../../assets/icons/registration/countries/andorra.svg"; // Andorra
import AO from "../../assets/icons/registration/countries/angola.svg"; // Angola
import AI from "../../assets/icons/registration/countries/anguilla.svg"; // Anguilla
import AG from "../../assets/icons/registration/countries/antiguaAndBarbuda.svg"; // Antigua and Barbuda
import AR from "../../assets/icons/registration/countries/argentina.svg"; // Argentina
import AM from "../../assets/icons/registration/countries/armenia.svg"; // Armenia
import AW from "../../assets/icons/registration/countries/aruba.svg"; // Aruba
import AU from "../../assets/icons/registration/countries/australia.svg"; // Australia
import AT from "../../assets/icons/registration/countries/austria.svg"; // Austria
import AZ from "../../assets/icons/registration/countries/azerbaijan.svg"; // Azerbaijan
import BS from "../../assets/icons/registration/countries/bahamas.svg"; // Bahamas
import BH from "../../assets/icons/registration/countries/bahrain.svg"; // Bahrain
import BD from "../../assets/icons/registration/countries/bangladesh.svg"; // Bangladesh
import BB from "../../assets/icons/registration/countries/barbados.svg"; // Barbados
import BY from "../../assets/icons/registration/countries/belarus.svg"; // Belarus
import BE from "../../assets/icons/registration/countries/belgium.svg"; // Belgium
import BZ from "../../assets/icons/registration/countries/belize.svg"; // Belize
import BJ from "../../assets/icons/registration/countries/benin.svg"; // Benin
import BM from "../../assets/icons/registration/countries/bermuda.svg"; // Bermuda
import BT from "../../assets/icons/registration/countries/bhutan.svg"; // Bhutan
import BO from "../../assets/icons/registration/countries/bolivia.svg"; // Bolivia
import BQ from "../../assets/icons/registration/countries/bonaire.svg"; // Bonaire
import BA from "../../assets/icons/registration/countries/bosniaAndHerzegovina.svg"; // Bosnia and Herzegovina
import BW from "../../assets/icons/registration/countries/botswana.svg"; // Botswana
import BR from "../../assets/icons/registration/countries/brazil.svg"; // Brazil
import IO from "../../assets/icons/registration/countries/britishIndianOceanTerritory.svg"; // British Indian Ocean Territory
import VG from "../../assets/icons/registration/countries/britishVirginIslands.svg"; // British Virgin Islands
import BN from "../../assets/icons/registration/countries/brunei.svg"; // Brunei
import BG from "../../assets/icons/registration/countries/bulgaria.svg"; // Bulgaria
import BF from "../../assets/icons/registration/countries/burkinaFaso.svg"; // Burkina Faso
import BI from "../../assets/icons/registration/countries/burundi.svg"; // Burundi
import CV from "../../assets/icons/registration/countries/capeVerde.svg"; // Cabo Verde
import KH from "../../assets/icons/registration/countries/cambodia.svg"; // Cambodia
import CM from "../../assets/icons/registration/countries/cameroon.svg"; // Cameroon
import CA from "../../assets/icons/registration/countries/canada.svg"; // Canada
import KY from "../../assets/icons/registration/countries/caymanIslands.svg"; // Cayman Islands
import CF from "../../assets/icons/registration/countries/centralAfricanRepublic.svg"; // Central African Republic
import TD from "../../assets/icons/registration/countries/chad.svg"; // Chad
import CL from "../../assets/icons/registration/countries/chile.svg"; // Chile
import CN from "../../assets/icons/registration/countries/china.svg"; // China
import CC from "../../assets/icons/registration/countries/cocosIsland.svg"; // Cocos Island
import CO from "../../assets/icons/registration/countries/colombia.svg"; // Colombia
import KM from "../../assets/icons/registration/countries/comoros.svg"; // Comoros
import CG from "../../assets/icons/registration/countries/republicOfTheCongo.svg"; // Congo
import CK from "../../assets/icons/registration/countries/cookIslands.svg"; // Cook Islands
import CR from "../../assets/icons/registration/countries/costaRica.svg"; // Costa Rica
import CI from "../../assets/icons/registration/countries/coteDivoire.svg"; // Côte d'Ivoire
import HR from "../../assets/icons/registration/countries/croatia.svg"; // Croatia
import CU from "../../assets/icons/registration/countries/cuba.svg"; // Cuba
import CW from "../../assets/icons/registration/countries/curacao.svg"; // Curacao
import CY from "../../assets/icons/registration/countries/cyprus.svg"; // Cyprus
import CZ from "../../assets/icons/registration/countries/czechRepublic.svg"; // Czech Republic
import CD from "../../assets/icons/registration/countries/democraticRepublicOfCongo.svg"; // Democratic Republic of Congo
import DK from "../../assets/icons/registration/countries/denmark.svg"; // Denmark
import DJ from "../../assets/icons/registration/countries/djibouti.svg"; // Djibouti
import DM from "../../assets/icons/registration/countries/dominica.svg"; // Dominica
import DO from "../../assets/icons/registration/countries/dominicanRepublic.svg"; // Dominican Republic
import TL from "../../assets/icons/registration/countries/eastTimor.svg"; // East Timor
import EC from "../../assets/icons/registration/countries/ecuador.svg"; // Ecuador
import EG from "../../assets/icons/registration/countries/egypt.svg"; // Ecuador
import GQ from "../../assets/icons/registration/countries/equatorialGuinea.svg"; // Equatorial Guinea
import ER from "../../assets/icons/registration/countries/eritrea.svg"; // Eritrea
import EE from "../../assets/icons/registration/countries/estonia.svg"; // Estonia
import ET from "../../assets/icons/registration/countries/ethiopia.svg"; // Ethiopia
import FK from "../../assets/icons/registration/countries/falklandIslands.svg"; // Falkland Islands
import FO from "../../assets/icons/registration/countries/faroeIslands.svg"; // Faroe Islands
import FJ from "../../assets/icons/registration/countries/fiji.svg"; // Fiji
import FI from "../../assets/icons/registration/countries/finland.svg"; // Finland
import FR from "../../assets/icons/registration/countries/france.svg"; // France
import PF from "../../assets/icons/registration/countries/frenchPolynesia.svg"; // French Polynesia
import GA from "../../assets/icons/registration/countries/gabon.svg"; // Gabon
import GM from "../../assets/icons/registration/countries/gambia.svg"; // Gambia
import GE from "../../assets/icons/registration/countries/georgia.svg"; // Georgia
import DE from "../../assets/icons/registration/countries/germany.svg"; // Germany
import GH from "../../assets/icons/registration/countries/ghana.svg"; // Ghana
import GI from "../../assets/icons/registration/countries/gibraltar.svg"; // Gibraltar
import GR from "../../assets/icons/registration/countries/greece.svg"; // Greece
import GL from "../../assets/icons/registration/countries/greenland.svg"; // Greenland
import GD from "../../assets/icons/registration/countries/grenada.svg"; // Grenada
import GU from "../../assets/icons/registration/countries/guam.svg"; // Guam
import GT from "../../assets/icons/registration/countries/guatemala.svg"; // Guatemala
import GG from "../../assets/icons/registration/countries/guernsey.svg"; // Guernsey
import GN from "../../assets/icons/registration/countries/guinea.svg"; // Guinea
import GW from "../../assets/icons/registration/countries/guineaBissau.svg"; // Guinea-Bissau
import GY from "../../assets/icons/registration/countries/guyana.svg"; // Guyana
import HT from "../../assets/icons/registration/countries/haiti.svg"; // Haiti
import VA from "../../assets/icons/registration/countries/holySee.svg"; // Holy See
import HN from "../../assets/icons/registration/countries/honduras.svg"; // Honduras
import HK from "../../assets/icons/registration/countries/hongKong.svg"; // Hong Kong
import HU from "../../assets/icons/registration/countries/hungary.svg"; // Hungary
import IS from "../../assets/icons/registration/countries/iceland.svg"; // Iceland
import IN from "../../assets/icons/registration/countries/india.svg"; // India
import ID from "../../assets/icons/registration/countries/indonesia.svg"; // indonesia
import IR from "../../assets/icons/registration/countries/iran.svg"; // Iran
import IQ from "../../assets/icons/registration/countries/iraq.svg"; // Iraq
import IE from "../../assets/icons/registration/countries/ireland.svg"; // Ireland
import IM from "../../assets/icons/registration/countries/isleOfMan.svg"; // Isle of Man
import IL from "../../assets/icons/registration/countries/israel.svg"; // Israel
import IT from "../../assets/icons/registration/countries/italy.svg"; // Italy
import JM from "../../assets/icons/registration/countries/jamaica.svg"; // Jamaica
import JP from "../../assets/icons/registration/countries/japan.svg"; // Japan
import JE from "../../assets/icons/registration/countries/jersey.svg"; // Jersey
import JO from "../../assets/icons/registration/countries/jordan.svg"; // Jordan
import KZ from "../../assets/icons/registration/countries/kazakhstan.svg"; // Kazakhstan
import KE from "../../assets/icons/registration/countries/kenya.svg"; // Kenya
import KI from "../../assets/icons/registration/countries/kiribati.svg"; // Kiribati
import KW from "../../assets/icons/registration/countries/kuwait.svg"; // Kuwait
import KG from "../../assets/icons/registration/countries/kyrgyzstan.svg"; // Kyrgyzstan
import LA from "../../assets/icons/registration/countries/laos.svg"; // Laos
import LV from "../../assets/icons/registration/countries/latvia.svg"; // Latvia
import LB from "../../assets/icons/registration/countries/lebanon.svg"; // Lebanon
import LS from "../../assets/icons/registration/countries/lesotho.svg"; // Lesotho
import LR from "../../assets/icons/registration/countries/liberia.svg"; // Liberia
import LY from "../../assets/icons/registration/countries/libya.svg"; // Libya
import LI from "../../assets/icons/registration/countries/liechtenstein.svg"; // Liechtenstein
import LT from "../../assets/icons/registration/countries/lithuania.svg"; // lithuania
import LU from "../../assets/icons/registration/countries/luxembourg.svg"; // Luxembourg
import MO from "../../assets/icons/registration/countries/macao.svg"; // Macao
import MG from "../../assets/icons/registration/countries/madagascar.svg"; // Madagascar
import MW from "../../assets/icons/registration/countries/malawi.svg"; // Malawi
import MY from "../../assets/icons/registration/countries/malaysia.svg"; // Malaysia
import MV from "../../assets/icons/registration/countries/maldives.svg"; // Maldives
import ML from "../../assets/icons/registration/countries/mali.svg"; // Mali
import MT from "../../assets/icons/registration/countries/malta.svg"; // Malta
import MH from "../../assets/icons/registration/countries/marshallIsland.svg"; // Marshall Islands
import MQ from "../../assets/icons/registration/countries/martinique.svg"; // Martinique
import MR from "../../assets/icons/registration/countries/mauritania.svg"; // Mauritania
import MU from "../../assets/icons/registration/countries/mauritius.svg"; // Mauritius
import MX from "../../assets/icons/registration/countries/mexico.svg"; // Mexico
import FM from "../../assets/icons/registration/countries/micronesia.svg"; // Micronesia
import MD from "../../assets/icons/registration/countries/moldova.svg"; // Moldova
import MC from "../../assets/icons/registration/countries/monaco.svg"; // Monaco
import MN from "../../assets/icons/registration/countries/mongolia.svg"; // Mongolia
import ME from "../../assets/icons/registration/countries/montenegro.svg"; // Montenegro
import MS from "../../assets/icons/registration/countries/montserrat.svg"; // Montserrat
import MA from "../../assets/icons/registration/countries/morocco.svg"; // Morocco
import MZ from "../../assets/icons/registration/countries/mozambique.svg"; // Mozambique
import MM from "../../assets/icons/registration/countries/myanmar.svg"; // Myanmar
import NA from "../../assets/icons/registration/countries/namibia.svg"; // Namibia
import NR from "../../assets/icons/registration/countries/nauru.svg"; // Nauru
import NP from "../../assets/icons/registration/countries/nepal.svg"; // Nepal
import NL from "../../assets/icons/registration/countries/netherlands.svg"; // Netherlands
import NZ from "../../assets/icons/registration/countries/newZealand.svg"; // New Zealand
import NI from "../../assets/icons/registration/countries/nicaragua.svg"; // Nicaragua
import NE from "../../assets/icons/registration/countries/niger.svg"; // Niger
import NG from "../../assets/icons/registration/countries/nigeria.svg"; // Nigeria
import NU from "../../assets/icons/registration/countries/niue.svg"; // Niue
import NF from "../../assets/icons/registration/countries/norfolkIsland.svg"; // Norfolk Island
import KP from "../../assets/icons/registration/countries/northKorea.svg"; // North Korea
import MP from "../../assets/icons/registration/countries/northernMarianasIslands.svg"; // Northern Mariana Islands
import MK from "../../assets/icons/registration/countries/northMacedonia.svg"; // North Macedonia
import NO from "../../assets/icons/registration/countries/norway.svg"; // Norway
import OM from "../../assets/icons/registration/countries/oman.svg"; // Oman
import PK from "../../assets/icons/registration/countries/pakistan.svg"; // Pakistan
import PW from "../../assets/icons/registration/countries/palau.svg"; // Palau
import PS from "../../assets/icons/registration/countries/palestine.svg"; // Palestine
import PA from "../../assets/icons/registration/countries/panama.svg"; // Panama
import PG from "../../assets/icons/registration/countries/papuaNewGuinea.svg"; // Papua New Guinea
import PY from "../../assets/icons/registration/countries/paraguay.svg"; // Paraguay
import PE from "../../assets/icons/registration/countries/peru.svg"; // Peru
import PH from "../../assets/icons/registration/countries/philippines.svg"; // Philippines
import PN from "../../assets/icons/registration/countries/pitcairnIslands.svg"; // Pitcairn
import PL from "../../assets/icons/registration/countries/poland.svg"; // Poland
import PT from "../../assets/icons/registration/countries/portugal.svg"; // Portugal
import PR from "../../assets/icons/registration/countries/puertoRico.svg"; // Puerto Rico
import QA from "../../assets/icons/registration/countries/qatar.svg"; // Qatar
import RO from "../../assets/icons/registration/countries/romania.svg"; // Romania
import RU from "../../assets/icons/registration/countries/russia.svg"; // Russia
import RW from "../../assets/icons/registration/countries/rwanda.svg"; // Rwanda
import BL from "../../assets/icons/registration/countries/stBarts.svg"; // Saint Barthélemy
import LC from "../../assets/icons/registration/countries/stLucia.svg"; // Saint Lucia
import VC from "../../assets/icons/registration/countries/stVincentAndTheGrenadines.svg"; // Saint Vincent and the Grenadines
import WS from "../../assets/icons/registration/countries/samoa.svg"; // Samoa
import SM from "../../assets/icons/registration/countries/sanMarino.svg"; // San Marino
import ST from "../../assets/icons/registration/countries/saoTomeAndPrince.svg"; // Sao Tome and Prince
import SA from "../../assets/icons/registration/countries/saudiArabia.svg"; // Saudi Arabia
import SN from "../../assets/icons/registration/countries/senegal.svg"; // Senegal
import RS from "../../assets/icons/registration/countries/serbia.svg"; // Serbia
import SC from "../../assets/icons/registration/countries/seychelles.svg"; // Seychelles
import SL from "../../assets/icons/registration/countries/sierraLeone.svg"; // Sierra Leone
import SG from "../../assets/icons/registration/countries/singapore.svg"; // Singapore
import SX from "../../assets/icons/registration/countries/sintMaarten.svg"; // Sint Maarten
import SK from "../../assets/icons/registration/countries/slovakia.svg"; // Slovakia
import SI from "../../assets/icons/registration/countries/slovenia.svg"; // Slovenia
import SB from "../../assets/icons/registration/countries/solomonIslands.svg"; // Solomon Islands
import SO from "../../assets/icons/registration/countries/somalia.svg"; // Somalia
import ZA from "../../assets/icons/registration/countries/southAfrica.svg"; // South Africa
import KR from "../../assets/icons/registration/countries/southKorea.svg"; // South Korea
import SS from "../../assets/icons/registration/countries/southSudan.svg"; // South Sudan
import ES from "../../assets/icons/registration/countries/spain.svg"; // Spain
import LK from "../../assets/icons/registration/countries/sriLanka.svg"; // Sri Lanka
import SD from "../../assets/icons/registration/countries/sudan.svg"; // Sudan
import SR from "../../assets/icons/registration/countries/suriname.svg"; // Suriname
import SE from "../../assets/icons/registration/countries/sweden.svg"; // Sweden
import CH from "../../assets/icons/registration/countries/switzerland.svg"; // Switzerland
import SY from "../../assets/icons/registration/countries/syria.svg"; // Syria
import TW from "../../assets/icons/registration/countries/taiwan.svg"; // Taiwan
import TJ from "../../assets/icons/registration/countries/tajikistan.svg"; // Tajikistan
import TZ from "../../assets/icons/registration/countries/tanzania.svg"; // Tanzania
import TH from "../../assets/icons/registration/countries/thailand.svg"; // Thailand
import TG from "../../assets/icons/registration/countries/togo.svg"; // Togo
import TK from "../../assets/icons/registration/countries/tokelau.svg"; // Tokelau
import TO from "../../assets/icons/registration/countries/tonga.svg"; // Tonga
import TT from "../../assets/icons/registration/countries/trinidadAndTobago.svg"; // Trinidad and Tobago
import TN from "../../assets/icons/registration/countries/tunisia.svg"; // Tunisia
import TR from "../../assets/icons/registration/countries/turkey.svg"; // Turkey
import TM from "../../assets/icons/registration/countries/turkmenistan.svg"; // Turkmenistan
import TC from "../../assets/icons/registration/countries/turksAndCaicos.svg"; // Turks and Caicos Islands
import TV from "../../assets/icons/registration/countries/tuvalu.svg"; // Tuvalu
import UG from "../../assets/icons/registration/countries/uganda.svg"; // Uganda
import UA from "../../assets/icons/registration/countries/ukraine.svg"; // Ukraine
import AE from "../../assets/icons/registration/countries/unitedArabEmirates.svg"; // United Arab Emirates
import GB from "../../assets/icons/registration/countries/unitedKingdom.svg"; // United Kingdom
import UM from "../../assets/icons/registration/countries/unitedStates.svg"; // United States Minor Outlying Islands
import US from "../../assets/icons/registration/countries/unitedStates.svg"; // United States
import UY from "../../assets/icons/registration/countries/uruguay.svg"; // uruguay
import UZ from "../../assets/icons/registration/countries/uzbekistan.svg"; // Uzbekistan
import VU from "../../assets/icons/registration/countries/vanuatu.svg"; // Vanuatu
import VE from "../../assets/icons/registration/countries/venezuela.svg"; // Venezuela
import VN from "../../assets/icons/registration/countries/vietnam.svg"; // Viet Nam
import VI from "../../assets/icons/registration/countries/virginIslands.svg"; // Virgin Islands
import EH from "../../assets/icons/registration/countries/sahrawiArabDemocraticRepublic.svg"; // Western Sahara
import YE from "../../assets/icons/registration/countries/yemen.svg"; // Yemen
import ZM from "../../assets/icons/registration/countries/zambia.svg"; // Zambia
import ZW from "../../assets/icons/registration/countries/zimbabwe.svg"; // Zimbabwe

export default { AF, AX, AL, DZ, AS, AD, AO, AI, AG, AR, AM, AW, AU, AT, AZ, BS, BH, BD, BB, BY, BE, BZ, BJ, BM, BT, BO, BQ, BA, BW, BR, IO, VG, BN, BG, BF, BI, CV, KH, CM, CA, KY, CF, TD, CL, CN, CC, CO, KM, CG, CK, CR, CI, HR, CU, CW, CY, CZ, CD, DK, DJ, DM, DO, TL, EC, EG, GQ, ER, EE, ET, FK, FO, FJ, FI, FR, PF, GA, GM, GE, DE, GH, GI, GR, GL, GD, GU, GT, GG, GN, GW, GY, HT, VA, HN, HK, HU, IS, IN, ID, IR, IQ, IE, IM, IL, IT, JM, JP, JE, JO, KZ, KE, KI, KW, KG, LA, LV, LB, LS, LR, LY, LI, LT, LU, MO, MG, MW, MY, MV, ML, MT, MH, MQ, MR, MU, MX, FM, MD, MC, MN, ME, MS, MA, MZ, MM, NA, NR, NP, NL, NZ, NI, NE, NG, NU, NF, KP, MP, MK, NO, OM, PK, PW, PS, PA, PG, PY, PE, PH, PN, PL, PT, PR, QA, RO, RU, RW, BL, LC, VC, WS, SM, ST, SA, SN, RS, SC, SL, SG, SX, SK, SI, SB, SO, ZA, KR, SS, ES, LK, SD, SR, SE, CH, SY, TW, TJ, TZ, TH, TG, TK, TO, TT, TN, TR, TM, TC, TV, UG, UA, AE, GB, UM, US, UY, UZ, VU, VE, VN, VI, EH, YE, ZM, ZW };