import { useState, useRef } from 'react';
import ToggleButton from './ToggleButton';
import AdaptiveModal from './AdaptiveModal';
import Select, { SelectProps, BaseOptionType, DefaultOptionType } from './Select';

export type SelectModalProps<ValueType, OptionType extends BaseOptionType> = {
	ns: string,
	label: React.ReactNode,
	selectionRender?: (value: any, option: OptionType | DefaultOptionType) => React.ReactNode,
} & SelectProps<ValueType, OptionType | DefaultOptionType>;

const SelectModal = <ValueType, OptionType extends BaseOptionType>({ ns, label, value, selectionRender, onSelect, ...props }: SelectModalProps<ValueType, OptionType>) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [isSelectOpen, setSelectOpen] = useState(false);
	const [visibleValue, setVisibleValue] = useState<React.ReactNode>("");
	const forwardRef = useRef<HTMLSelectElement>(null);
	const openModal = () => {
		setModalOpen(true);
		setTimeout(() => setSelectOpen(true), 300);
		setTimeout(() => forwardRef.current?.focus(), 400);
	};
	const closeModal = () => {
		forwardRef.current?.blur();
		setSelectOpen(false);
		setTimeout(() => setModalOpen(false), 300);
	};
	const onBaseSelect: SelectModalProps<ValueType, OptionType | DefaultOptionType>["onSelect"] = (value, option) => {
		if (onSelect) {
			onSelect(value, option);
		}
		setVisibleValue(selectionRender ? selectionRender(value, option) : option.label);
		closeModal();
	};
	return (
		<>
			<ToggleButton ns={ns} label={label} value={visibleValue} onClick={openModal} />
			<AdaptiveModal ns={ns} isOpen={isModalOpen} onCancel={closeModal}>
				<Select ns={ns} onSelect={onBaseSelect} open={isSelectOpen} value={value} {...props} />
			</AdaptiveModal>
		</>
	);
};

export type { BaseOptionType, DefaultOptionType } from './Select';
export { Option } from './Select';
export default SelectModal;