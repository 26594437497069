import type { Action, UserPhoneCodeDictionary } from '../actions';
import { isThunkAction } from '../actions';

export type UserState = {
	uuid: string
	country: string
	email: string
	emailCode: string
	phone: string
	phonePrefix: string
	phoneCode: string
	password: string
	passwordConfirm: string
	phoneCodes: {
		data: UserPhoneCodeDictionary,
		loaded: boolean,
	},
	registered: boolean
	emailSaved: boolean
	emailConfirmed: boolean
	phoneSaved: boolean
	phoneConfirmed: boolean
	passwordValid: boolean
	passwordConfirmValid: boolean
	passwordSaved: boolean
};

export const userState: UserState = {
	uuid: "",
	country: "",
	email: "",
	emailCode: "",
	phone: "",
	phonePrefix: "",
	phoneCode: "",
	password: "",
	passwordConfirm: "",
	phoneCodes: {
		data: [],
		loaded: false,
	},
	registered: false,
	emailSaved: false,
	emailConfirmed: false,
	phoneSaved: false,
	phoneConfirmed: false,
	passwordValid: false,
	passwordConfirmValid: false,
	passwordSaved: false,
};

export default function userReducer(state: UserState, action: Action): UserState {
	if (isThunkAction(action)) {
		return state;
	}
	switch (action.type) {
		case "user.getPhoneCodes":
			return { ...state, phoneCodes: { loaded: true, data: action.phoneCodes } };
		case "user.setCountry":
			return { ...state, country: action.country };
		case "user.register":
			return { ...state, registered: true, uuid: action.uuid };
		case "user.setEmail":
			return { ...state, email: action.email };
		case "user.saveEmail":
			return { ...state, emailSaved: true };
		case "user.setEmailCode":
			return { ...state, emailCode: action.emailCode };
		case "user.confirmEmail":
			return { ...state, emailConfirmed: true };
		case "user.setPhone":
			return { ...state, phone: action.phone };
		case "user.setPhonePrefix":
			return { ...state, phonePrefix: action.phonePrefix };
		case "user.savePhone":
			return { ...state, phoneSaved: true }
		case "user.setPhoneCode":
			return { ...state, phoneCode: action.phoneCode };
		case "user.confirmPhone":
			return { ...state, phoneConfirmed: true }
		case "user.setPassword":
			return { ...state, password: action.password };
		case "user.setPasswordValid":
			return { ...state, passwordValid: action.valid };
		case "user.confirmPassword":
			return { ...state, passwordConfirm: action.password }
		case "user.confirmPasswordValid":
			return { ...state, passwordConfirmValid: action.valid };
		case "user.savePassword":
			return { ...state, passwordSaved: true }
		default:
			return state;
	}
}