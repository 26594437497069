import { Modal } from "antd";
import { CloseModalIcon } from "../../utils/styles/common";
import Adaptive from './Adaptive';
import CustomModal from './CustomModal';

type AdaptiveModalProps = {
	ns: string,
	onCancel: () => void,
	isOpen: boolean,
	children: JSX.Element[] | JSX.Element,
};

const AdaptiveModal = ({ ns, onCancel, isOpen, children }: AdaptiveModalProps) => (
	<Adaptive mobile={children => (
		<CustomModal className="registration" onCancel={onCancel} isOpen={isOpen}>
			<>{children}</>
		</CustomModal>
	)} desktop={children => (
		<Modal closeIcon={CloseModalIcon} footer={null} onCancel={onCancel} open={isOpen} wrapClassName={`${ns}-modal`}>
			<>{children}</>
		</Modal>
	)}>{children}</Adaptive>
);

export default AdaptiveModal;