import connect, { setPassword, setPasswordValid, confirmPassword, confirmPasswordValid, savePassword } from '../../../api';
import createPasswordImage from "../../../assets/images/registration/password.webp";
import Section from '../../ui/Section';
import Password from '../../ui/Password';
import "./index.scss";

export const CreatePassword = ({ password, passwordConfirm, setPassword, setPasswordValid, confirmPassword, confirmPasswordValid, savePassword }: {
	password: string,
	passwordConfirm: string,
	setPassword: (password: string) => void,
	setPasswordValid: (valid: boolean) => void,
	confirmPassword: (password: string) => void,
	confirmPasswordValid: (valid: boolean) => void,
	savePassword: () => void,
}) => {
	return (
		<Section title="Create password" label="Continue" image={createPasswordImage} onSubmit={savePassword} description={<p>This password will be used to log into your account</p>}>
			<div className="create-pwd-block">
				<Password label="Password" value={password} onValueChange={setPassword} onPasswordCheck={setPasswordValid} />
				<Password label="Confirm password" value={passwordConfirm} onValueChange={confirmPassword} onPasswordCheck={confirmPasswordValid} />
			</div>
		</Section>
	);
};

export default connect(
	({ user: { password, passwordConfirm } }) => ({ password, passwordConfirm }),
	dispatch => ({
		setPassword: (password) => dispatch(setPassword(password)),
		setPasswordValid: (valid) => dispatch(setPasswordValid(valid)),
		confirmPassword: (password) => dispatch(confirmPassword(password)),
		confirmPasswordValid: (valid) => dispatch(confirmPasswordValid(valid)),
		savePassword: () => dispatch(savePassword()),
	})
)(CreatePassword);