import connect, { setRole, saveRole, BusinessRole } from '../../../api';
import Section from '../../ui/Section';
import styles from "./index.module.scss";

type BusinessRoleSectionProps = {
	role: BusinessRole,
	setRole: (role: BusinessRole) => void,
	saveRole: () => void,
};

const BusinessRoleSection = ({ role, setRole, saveRole }: BusinessRoleSectionProps) => {
	const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRole(e.target.value as BusinessRole);
	}
	return (
		<Section title="Tell us about your role in the company" label="Continue" onSubmit={saveRole} description={<></>}>
			<div className={styles.registerForm} >
				<label className={role === "director" ? styles.labelActive : styles.labelPassive} htmlFor="director">
					<input type="radio" name="businessRole" id="director" value="director" checked={role === "director"} onChange={onOptionChange} />
					<p>I’m the only director and significant shareholder</p>
					<span>You are the only director and the only owner with more than 25% of the business</span>
				</label>
				<label className={role === "directors" ? styles.labelActive : styles.labelPassive} htmlFor="directors">
					<input type="radio" name="businessRole" id="directors" value="directors" checked={role === "directors"} onChange={onOptionChange} />
					<p>I’m one of several directors or significant shareholder</p>
					<span>There are other directors or shareholders with greater than 25% ownership</span>
				</label>
				<label className={role === "noDirector" ? styles.labelActive : styles.labelPassive} htmlFor="noDirector">
					<input type="radio" name="businessRole" id="noDirector" value="noDirector" checked={role === "noDirector"} onChange={onOptionChange} />
					<p>I’m neither a director nor significant shareholder</p>
					<span>You are applying on behalf of your employer or client</span>
				</label>
			</div>
		</Section>
	);
};

export default connect(
	({ business: { role } }) => ({ role }),
	dispatch => ({
		setRole: (role) => dispatch(setRole(role)),
		saveRole: () => dispatch(saveRole()),
	}),
)(BusinessRoleSection);