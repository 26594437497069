import { apiRequest } from '../api';
import type { ThunkAction } from "./index";
import dayjs from 'dayjs';

export type PersonDate = dayjs.Dayjs | null;

export type PersonAction =
	| { type: "person.setFirstName", firstName: string }
	| { type: "person.setLastName", lastName: string }
	| { type: "person.setBirthDate", birthDate: PersonDate }
	| { type: "person.savePersonal" };

export const setFirstName = (firstName: string): PersonAction => ({ type: "person.setFirstName", firstName: firstName });
export const setLastName = (lastName: string): PersonAction => ({ type: "person.setLastName", lastName: lastName });
export const setBirthDate = (birthDate: PersonDate): PersonAction => ({ type: "person.setBirthDate", birthDate: birthDate });

export const savePersonal = (): ThunkAction => apiRequest<{}>(
	({ person: { firstName, lastName, birthDate } }) => ({ firstName, lastName, birthDate: birthDate?.format("YYYY-MM-DD") }),
	{ url: "/personal/save" },
	() => (dispatch) => {
		dispatch({ type: "person.savePersonal" });
		dispatch({ type: "common.goNext" });
	},
);
